<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-on:submit="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-select
                                v-if="showCustomerId"
                                v-model="pesaje.customer_id"
                                :items="customers"
                                :rules="customerIdRules"
                                color="#009A3F"
                                outlined
                                item-text="business_name"
                                item-value="id"
                                label="Cliente"
                        ></v-select>
                        <v-autocomplete
                                v-model="pesaje.vehicle"
                                :items="vehicles"
                                :loading="isLoadingSearch"
                                :search-input.sync="search"
                                color="#009A3F"
                                hide-no-data
                                hide-selected
                                item-text="license_plate"
                                item-value="id"
                                label="Vehículos"
                                placeholder="Busca un Vehículo"
                                prepend-icon="mdi-database-search"
                                return-object
                                @change='onSelectTemplate'
                        ></v-autocomplete>
                        <v-text-field
                                label="Marca"
                                v-model="currentVehicle.brand_name"
                                color="#009A3F"
                                required
                                readonly
                                outlined></v-text-field>
                        <v-text-field
                                label="Modelo"
                                v-model="currentVehicle.model_name"
                                color="#009A3F"
                                readonly
                                required
                                outlined></v-text-field>
                        <v-text-field
                                label="Tipo de eje"
                                v-model="currentVehicle.type_axis_name"
                                color="#009A3F"
                                readonly
                                required
                                outlined></v-text-field>

                        <v-menu v-model="fromDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        label="Fecha de Asignación"
                                        color="#009A3F"
                                        prepend-icon="event"
                                        readonly
                                        :value="date_value"
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    locale="es-ES"
                                    v-model="pesaje.date"
                                    no-title
                                    @input="fromDateMenu = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-textarea
                                outlined
                                v-model="pesaje.observation"
                                color="#009A3F"
                                name="input-7-4"
                                label="Observación"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                        <Pesaje2D v-if="currentVehicle.type_axis_name === '2D'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje2D>
                        <Pesaje2D2 v-if="currentVehicle.type_axis_name === '2D2'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje2D2>
                        <Pesaje2DD v-if="currentVehicle.type_axis_name === '2DD'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje2DD>
                        <Pesaje2DDWB v-if="currentVehicle.type_axis_name === '2DDWB'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje2DDWB>
                        <Pesaje4x2 v-if="currentVehicle.type_axis_name === '4x2'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje4x2>
                        <Pesaje22D v-if="currentVehicle.type_axis_name === '22D'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje22D>
                        <Pesaje22D2 v-if="currentVehicle.type_axis_name === '22D2'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje22D2>
                        <Pesaje22DDD v-if="currentVehicle.type_axis_name === '22DDD'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></Pesaje22DDD>
                        <PesajeS4 v-if="currentVehicle.type_axis_name === 'S4'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS4>
                        <PesajeS4WB v-if="currentVehicle.type_axis_name === 'S4WB'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS4WB>
                        <PesajeS6WB v-if="currentVehicle.type_axis_name === 'S6WB'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS6WB>
                        <PesajeS8 v-if="currentVehicle.type_axis_name === 'S8'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS8>
                        <PesajeS12 v-if="currentVehicle.type_axis_name === 'S12'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS12>
                        <PesajeS16 v-if="currentVehicle.type_axis_name === 'S16'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS16>
                        <PesajeS162 v-if="currentVehicle.type_axis_name === 'S16-2'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS162>
                        <PesajeS243 v-if="currentVehicle.type_axis_name === 'S24-3'" :currentVehicle="currentVehicle"
                                  @changePesaje="changePesaje"></PesajeS243>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn v-if="!pesaje.id" :disabled="loading" :loading="loading" @click="submit">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn v-if="pesaje.id" :disabled="loading" :loading="loading" @click="submit">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import unitService from "@/services/units/unitService";
    import processPesajeService from '@/services/processPesajeService'
    import Pesaje2D from '@/components/process/pesaje/Pesaje2D';
    import Pesaje2D2 from '@/components/process/pesaje/Pesaje2D2';
    import Pesaje2DD from '@/components/process/pesaje/Pesaje2DD';
    import Pesaje2DDWB from '@/components/process/pesaje/Pesaje2DDWB';
    import Pesaje4x2 from '@/components/process/pesaje/Pesaje4x2';
    import Pesaje22D from '@/components/process/pesaje/Pesaje22D';
    import Pesaje22D2 from '@/components/process/pesaje/Pesaje22D2';
    import Pesaje22DDD from '@/components/process/pesaje/Pesaje22DDD';
    import PesajeS4 from '@/components/process/pesaje/PesajeS4';
    import PesajeS4WB from '@/components/process/pesaje/PesajeS4WB';
    import PesajeS6WB from '@/components/process/pesaje/PesajeS6WB';
    import PesajeS8 from '@/components/process/pesaje/PesajeS8';
    import PesajeS12 from '@/components/process/pesaje/PesajeS12';
    import PesajeS16 from '@/components/process/pesaje/PesajeS16';
    import PesajeS162 from '@/components/process/pesaje/PesajeS16-2';
    import PesajeS243 from '@/components/process/pesaje/PesajeS24-3';
    import customerService from '@/services/administrations/customerService';

    export default {
        props: ["pesaje"],
        name: 'PesajeForm',
        components: {
            Pesaje2D,
            Pesaje2D2,
            Pesaje2DD,
            Pesaje2DDWB,
            Pesaje4x2,
            Pesaje22D,
            Pesaje22D2,
            Pesaje22DDD,
            PesajeS4,
            PesajeS4WB,
            PesajeS6WB,
            PesajeS8,
            PesajeS12,
            PesajeS16,
            PesajeS162,
            PesajeS243,
        },
        data: () => ({
            loading: false,
            snackbar: false,
            textError: '',
            fromDateMenu: false,
            date: null,
            menu: false,
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 2, name: 'Inactivo'}
            ],
            search: null,
            vehicles: [],
            isLoadingSearch: false,
            currentVehicle: {},
            showCustomerId: false,
            customers: [],
            customerIdRules: [
                v => !!v || 'Cliente es requerido',
            ],
        }),
        watch: {
            search(val) {
                // Items have already been requested
                if (this.isLoadingSearch) return;

                this.isLoadingSearch = true;
                // Lazily load input items
                unitService.search(val, 0, this.pesaje.customer_id).then(response => {
                    this.vehicles = response.data.data;
                    this.isLoadingSearch = false;
                }).catch(error => {
                    this.isLoadingSearch = false;
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },

        },
        computed: {
            date_value() {
                return this.formatDate(this.pesaje.date);
            },
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.pesaje.id !== 0 && this.isAdmin) {
                this.showCustomerId = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            onSelectTemplate(e) {
                this.pesaje.vehicle_id = e.id;
                this.currentVehicle = e;
            },
            changePesaje($event) {
                this.pesaje.weight_check_axises = $event;
            },
            submit() {
                if (this.showCustomerId) {
                    if (!this.pesaje.customer_id) {
                        this.textError = 'Seleccione un cliente';
                        this.snackbar = true;
                        return;
                    }
                }
                if (!this.pesaje.vehicle_id) {
                    this.textError = 'Seleccione un vehículo';
                    this.snackbar = true;
                    return;
                }
                if (!this.pesaje.date) {
                    this.textError = 'Seleccione una fecha';
                    this.snackbar = true;
                    return;
                }
                if (!this.pesaje.weight_check_axises || this.pesaje.weight_check_axises.length === 0) {
                    this.textError = 'Complete los pesos en los ejes';
                    this.snackbar = true;
                    return;
                }

                this.loading = true;
                processPesajeService.store(this.pesaje).then(response => {
                    this.$router.push({path: '/process/pesaje'})
                }).catch(error => {
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
        }
    }
</script>

<style type="text/css">
    .div-eje .v-text-field--outlined > .v-input__control > .v-input__slot {
        background: #ccc;
    }
</style>
