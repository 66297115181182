<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" @submit.prevent="submit">
            <v-container>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="design.brand_id"
                                color="#009A3F"
                                :items="brands"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Marcas"
                        ></v-select>

                        <v-text-field
                                v-model="design.name"
                                :rules="rules.name"
                                color="#009A3F"
                                label="Diseño"
                                required
                                outlined
                        ></v-text-field>

                        <v-select
                                v-model="design.status"
                                :items="allstatus"
                                color="#009A3F"
                                outlined
                                item-text="name"
                                item-value="id"
                                label="Estado"
                        ></v-select>

                        <div>
                            <v-checkbox
                                    v-model="design.retread"
                                    label="Reencauche"
                                    color="#009A3F"
                            ></v-checkbox>
                        </div>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6">
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="!design.id" :loading="loading">
                                Guardar
                            </v-btn>
                        </div>
                        <div class="button-submit centerdiv-container button-form">
                            <v-btn type="submit"
                                   :disabled="!valid || loading"
                                   v-if="design.id" :loading="loading">
                                Actualizar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import tireDesignService from '@/services/tires/tireDesignService'
    import tireBrandService from "@/services/tires/tireBrandService";

    export default {
        props: ["design"],
        name: 'DesignForm',
        components: {},
        data: () => ({
            snackbar: false,
            textError: '',
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ],
            brands: []
        }),
        mounted() {
            this.getBrands();
        },
        methods: {
            submit() {
                this.loading = true;
                if (this.design.id) {
                    tireDesignService.update(this.design).then(response => {
                        this.$router.push({path: '/tires/designs'})
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                } else {
                    tireDesignService.store(this.design).then(response => {
                        this.$router.push({path: '/tires/designs'})
                    }, error => {
                        this.loading = false;
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    });
                }
            },
            getBrands() {
                tireBrandService.all().then(response => {
                    this.brands = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
