<template>
    <div class="pad">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red"
                       text
                       v-bind="attrs"
                       @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="vehicleFuels" @deleteItem="deleteItemConfirm" :withCustomerSelect="isAdmin"
                   @onSelectCustomerId="onSelectCustomerId"/>
        <v-dialog v-model="dialogDelete" max-width="400px" height="160px">
            <v-card>
                <v-card-title class="headline title-modal">¿Seguro de borrar este item?</v-card-title>
                
                    <div class="d-b pad-bottom">
                        <v-btn class="btn-cancel" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                        <v-btn color="button-export" :disabled="loading" :loading="loading" @click="deleteItemApi">
                            Confirmar
                        </v-btn>
                    </div>
                    
                
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import vehicleFuelService from "@/services/vehicleFuelService";

    export default {
        name: "Fuel",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Proceso', disabled: true, to: {name: 'Fuel'}, exact: true}
            ],
            snackbar: false,
            textError: '',
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            vehicleFuels: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                options: {
                    customer_id: null,
                    search: '',
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'vehicle.customer.business_name'},
                    {text: 'Placa/número', value: 'vehicle.license_plate'},
                    {text: 'Fecha', value: 'date'},
                    {text: 'Odómetro', value: 'km_travel'},
                    {text: 'Horas Recorridas', value: 'time_travel'},
                    {text: 'Combustible', value: 'fuel.name'},
                    {text: 'Cantidad de combustible', value: 'fuel_value'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Control de combustible',
                titleAddButton: 'Agregar Control',
                addlink: '/process/fuel/create',
                editlink: '/process/fuel/edit/',
            },
            user: null,
            isAdmin: false,
            firstLoading: true,
        }),
        mounted() {
            this.getDataFromApi();
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            /*if (this.user.roles.find(role => (role.name === 'admin' || role.name === 'inspector') && role.name !== 'customer')) {
                this.isAdmin = true;
            }*/
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                vehicleFuelService.datatable(this.options.options).then(response => {
                    this.vehicleFuels = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            onSelectCustomerId(e) {
                this.options.options.customer_id = e;
                this.getDataFromApi();
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                vehicleFuelService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.error(error);
                });
            }
        }
    };
</script>

<style scoped>
</style>
