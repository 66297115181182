<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="unittype.name"
                            :rules="rules.name"
                            label="Tipo de Unidad"
                            color="#009A3F"
                            required
                            outlined
                    ></v-text-field>

                    <v-file-input
                            accept="image/*"
                            label="Imagen"
                            @change="handleImage($event)"
                            color="#009A3F"
                            outlined
                            prepend-icon="mdi-camera"
                    ></v-file-input>

                    <div v-if="unittype.photo_url" class="centerdiv-container">
                        <v-img
                                max-width="22rem"
                                v-bind:src="unittype.photo_url"
                        ></v-img>
                    </div>

                    <v-select
                            v-model="unittype.status"
                            :items="allstatus"
                            outlined
                            color="#009A3F"
                            item-text="name"
                            item-value="id"
                            label="Estado"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                                :disabled="!valid || loading"
                                v-if="!unittype.id" :loading="loading"
                        >
                            Guardar
                        </v-btn>
                    </div>
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                                :disabled="!valid || loading"
                                v-if="unittype.id" :loading="loading"
                        >
                            Actualizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import unitTypeService from '@/services/units/unitTypeService'
    import resourceService from '@/services/resourceService'

    export default {
        props: ["unittype"],
        nama: 'UnitTypeForm',
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        methods: {
            submit() {
                this.loading = true;
                if (this.unittype.id) {
                    unitTypeService.update(this.unittype).then(response => {
                        this.$router.push({path: '/units/types'})
                    }).catch(error => {
                        console.log('error', error);
                        this.loading = false;
                    });
                } else {
                    unitTypeService.store(this.unittype).then(response => {
                        this.$router.push({path: '/units/types'})
                    }).catch(error => {
                        console.error('error', error);
                        this.loading = false;
                    });
                }
            },
            handleImage(e) {
                const formData = new FormData();
                formData.append('file', e);
                formData.append('path', 'unittypes');
                resourceService.uploadFile(formData).then(response => {
                    this.unittype.photo = response.data.data.name;
                    this.unittype.photo_url = response.data.data.url;
                }).catch(error => {
                    console.error('error', error);
                });
            },
        }
    }
</script>

<style type="text/css">


</style>
