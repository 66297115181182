<template>
   <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
          <v-container>
             <v-row >
                <v-col cols="12" sm="6" d="8"  class="d-flex justify-left">
                    <div><router-link to="/units/routes" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Nueva Ruta</h1>
                </v-col>
            </v-row>
             <v-row align="center" justify="center">
                 <v-card width='90%'>
                        <UnitRouteForm :unitroute="unitroute"/>
                 </v-card>
             </v-row>
          </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitRouteForm from '@/components/units/route/UnitRouteForm'

    export default {
        name:'routeAdd',
        components:{
            Breadcrumbs,
            UnitRouteForm
        },
        data:()=>({
             breadcrumbs: [
                {text: 'Rutas', disabled: false, to: {name:'UnitRoute'}, exact: true},
                {text: 'Crear Ruta', disabled: true, to: {name:'UnitRouteAdd'}, exact: true},
            ],
            unitroute : {
                id:null,
                name: '',
                status : 1,
                status_nice: 'Active'
            }

        })
    }
</script>

<style scoped>

</style>
