<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/administration/customers" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Cliente</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <CustomerForm :customer="customer"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import CustomerForm from '@/components/administrations/customer/CustomerForm';

    export default {
        name: "CustomerAdd",
        components: {
            Breadcrumbs,
            CustomerForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Clientes', disabled: false, to: {name: 'Customer'}, exact: true},
                {text: 'Crear Cliente', disabled: true, to: {name: 'CustomerAdd'}, exact: true},
            ],
            customer: {
                business_name: '',
                ruc: '',
                address: '',
                email: '',
                contact_person: '',
                maintenance_manager: '',
                maintenance_chief: '',
                minor_rtd: null,
                major_rtd: null,
                status: 1,
            }
        })
    }
</script>

<style scoped>

</style>
