<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/units/fuels" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Combustible</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitFuelForm :unitfuel="unitfuel"/>
                </v-card>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitFuelForm from '@/components/units/fuel/UnitFuelForm';
    import unitFuelService from '@/services/units/unitFuelService';

    export default {
        name: 'UnitFuelEdit',
        components: {
            Breadcrumbs,
            UnitFuelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Rutas', disabled: false, to: {name: 'UnitFuel'}, exact: true},
                {text: 'Editar Ruta', disabled: true, to: {name: 'UnitFuelEdit'}, exact: true}
            ],
            unitfuel: {
                id: null,
                name: '',
                status: null,
                status_nice: '',
            },
        }),
        created() {
            this.getUnitFuelById(this.$route.params.id);
        },
        methods: {
            getUnitFuelById(id) {
                unitFuelService.show(id).then(response => {
                    this.unitfuel = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
