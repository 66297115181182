<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/units/wearlevels" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Tipo de Unidad por Eje</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitWearLevelForm :unitwearlevel="unitwearlevel"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitWearLevelForm from '@/components/units/wearlevel/UnitWearLevelForm';

    export default {
        name: 'UnitWearLevelAdd',
        components: {
            Breadcrumbs,
            UnitWearLevelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Tipo de unidad por Eje', disabled: false, to: {name: 'UnitWearLevel'}, exact: true},
                {text: 'Agregar Tipo de unidad por Eje', disabled: true, to: {name: 'UnitWearLevelAdd'}, exact: true},
            ],
            unitwearlevel: {
                id: null,
                name: '',
                status: 1,
                axis: null,
                position: null,
                photo: null,
                photo_url: null,
                status_nice: 'Active',
            }
        })
    }
</script>

<style scoped>

</style>
