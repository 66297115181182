<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/tires/causals" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Causal de Retiro</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <CausalForm :causal="causal"/>
                </v-card>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import CausalForm from '@/components/tires/causal/CausalForm';
    import tireCausalService from '@/services/tires/tireCausalService';

    export default {
        name: 'CausalEdit',
        components: {
            Breadcrumbs,
            CausalForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Causales', disabled: false, to: {name: 'TiresCausal'}, exact: true},
                {text: 'Editar Causal', disabled: true, to: {name: 'TiresCausalEditar'}, exact: true},
            ],
            causal: {
                id: null,
                name: '',
                status: null,
                status_nice: '',
            },
        }),
        created() {
            this.getTireCausalById(this.$route.params.id);
        },
        methods: {
            getTireCausalById(id) {
                tireCausalService.show(id).then(response => {
                    this.causal = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
