<template>
    <div class="pad">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red"
                       text
                       v-bind="attrs"
                       @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="pesajes" :hasDeleteItem="false" :withCustomerSelect="isAdmin" @onSelectCustomerId="onSelectCustomerId"/>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import processPesajeService from "@/services/processPesajeService";

    export default {
        name: "Pesaje",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Proceso', disabled: true, to: {name: 'Pesaje'}, exact: true}
            ],
            snackbar: false,
            textError: '',
            loading: false,
            pesajes: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 5,
                options: {
                    customer_id: null,
                    search: '',
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'customer.business_name'},
                    {text: 'Código', value: 'code'},
                    {text: 'Unidad', value: 'vehicle.license_plate'},
                    {text: 'Fecha', value: 'date'},
                    {text: 'Marca', value: 'vehicle.brand_of_unit.name'},
                    {text: 'Modelo', value: 'vehicle.unit_model.name'},
                    {text: 'Tipo de eje', value: 'vehicle.unit_type_axis.name'},
                    {text: 'Posiciones', value: 'vehicle.unit_type_axis.position'},
                    {text: 'Acciones', value: 'actions', sortable: false},
                ],
                title: 'Pesaje',
                titleAddButton: 'Agregar Pesaje',
                addlink: '/process/pesaje/create/',
                editlink: '/process/pesaje/edit/',
            },
            user: null,
            isAdmin: false,
            firstLoading: true,
        }),
        mounted() {
            this.getDataFromApi();
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                processPesajeService.datatable(this.options.options).then(response => {
                    this.pesajes = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            onSelectCustomerId(e) {
                this.options.options.customer_id = e;
                this.getDataFromApi();
            },
        }
    };
</script>

<style scoped>
</style>
