<template>
  <div class="pad">
    <h1 class="style-title">Mantenimiento</h1>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
  components: {},
};
</script>

<style scoped></style>
