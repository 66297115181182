<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/administration/inspectors" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Inspector</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UserForm :user="user" :role_name="'inspector'" :urlRedirect="'/administration/inspectors'"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UserForm from '@/components/administrations/user/UserForm';
    import userService from "@/services/administrations/userService";

    export default {
        name: "InspectorEdit",
        components: {
            Breadcrumbs,
            UserForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inspectores', disabled: false, to: {name: 'Inspector'}, exact: true},
                {text: 'Editar Inspector', disabled: true, to: {name: 'InspectorEdit'}, exact: true},
            ],
            user: {
                name: '',
                dni: '',
                email: '',
                status: null,
            }
        }),
        created() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                userService.show(id).then(response => {
                    this.user = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
