<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/process/fuel" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Control</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <FuelForm :fuel="fuel" :vehiclesParam="vehicle" v-if="fuel.id"/>
                </v-card>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import FuelForm from '@/components/process/fuel/FuelForm';
    import vehicleFuelService from '@/services/vehicleFuelService'

    export default {
        name: 'ProcessFuelEdit',
        components: {
            Breadcrumbs,
            FuelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Control de Combustible', disabled: false, to: {name: 'Fuel'}, exact: true},
                {text: 'Editar Control de Combustible', disabled: true, to: {name: 'ProcessFuelEdit'}, exact: true}
            ],
            fuel: {
                customer_id: null,
                id: null,
                vehicle_id: null,
                date: null,
                km_travel: null,
                time_travel: null,
                fuel_id: null,
                fuel_value: null,
                cost: null,
            },
            vehicle: null
        }),
        mounted() {
            this.getprocessFuelById(this.$route.params.id);
        },
        methods: {
            getprocessFuelById(id) {
                vehicleFuelService.show(id).then(response => {
                    this.fuel = response.data.data;
                    this.vehicle = this.fuel.vehicle;
                    console.log(this.fuel);
                });
            }
        }
    }
</script>

<style scoped>

</style>
