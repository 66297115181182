<template>
    <div class="pad">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1 class="style-title">Consumo de Combustible por Neumático</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-form @submit.prevent="submit">
                        <v-row>
                            <v-col v-if="isAdmin" cols="12" md="3">
                                <v-select
                                        v-model="form.customer_id"
                                        :items="customers"
                                        color="#009A3F"
                                        item-text="business_name"
                                        item-value="id"
                                        label="Cliente"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-menu v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Desde"
                                                color="#009A3F"
                                                prepend-icon="event"
                                                readonly
                                                :value="from_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            color="#009A3F"
                                            v-model="form.from_date"
                                            no-title
                                            @input="fromDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-menu v-model="toDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Hasta"
                                                color="#009A3F"
                                                prepend-icon="event"
                                                readonly
                                                :value="to_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            color="#009A3F"
                                            v-model="form.to_date"
                                            no-title
                                            @input="toDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.brand_id"
                                        :items="brands"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Marcas"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <fieldset class="py-2 px-2">
                                    <legend>Ubicación del Neumático</legend>
                                    <v-row>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="1"
                                                    v-model="form.positions"
                                                    label="Posc. 1"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="2"
                                                    v-model="form.positions"
                                                    label="Posc. 2"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="3"
                                                    v-model="form.positions"
                                                    label="Posc. 3"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="4"
                                                    v-model="form.positions"
                                                    label="Posc. 4"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="5"
                                                    v-model="form.positions"
                                                    label="Posc. 5"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="6"
                                                    v-model="form.positions"
                                                    label="Posc. 6"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="7"
                                                    v-model="form.positions"
                                                    label="Posc. 7"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="8"
                                                    v-model="form.positions"
                                                    label="Posc. 8"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="9"
                                                    v-model="form.positions"
                                                    label="Posc. 9"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="10"
                                                    v-model="form.positions"
                                                    label="Posc. 10"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="11"
                                                    v-model="form.positions"
                                                    label="Posc. 11"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="12"
                                                    v-model="form.positions"
                                                    label="Posc. 12"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="13"
                                                    v-model="form.positions"
                                                    label="Posc. 13"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="14"
                                                    v-model="form.positions"
                                                    label="Posc. 14"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="15"
                                                    v-model="form.positions"
                                                    label="Posc. 15"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="16"
                                                    v-model="form.positions"
                                                    label="Posc. 16"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="17"
                                                    v-model="form.positions"
                                                    label="Posc. 17"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="18"
                                                    v-model="form.positions"
                                                    label="Posc. 18"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="19"
                                                    v-model="form.positions"
                                                    label="Posc. 19"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="20"
                                                    v-model="form.positions"
                                                    label="Posc. 20"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="21"
                                                    v-model="form.positions"
                                                    label="Posc. 21"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="22"
                                                    v-model="form.positions"
                                                    label="Posc. 22"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="23"
                                                    v-model="form.positions"
                                                    label="Posc. 23"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="24"
                                                    v-model="form.positions"
                                                    label="Posc. 24"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-btn depressed class="button-export" type="submit" :disabled="loading" :loading="loading">
                                    Exportar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import customerService from "@/services/administrations/customerService";
    import vehicleFuelService from "@/services/vehicleFuelService";
    import tireBrandService from "@/services/tires/tireBrandService";

    export default {
        name: "FuelPerTire",
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {
                    text: 'Reporte de Consumo de Combustible por Neumático',
                    disabled: true,
                    to: {name: 'FuelPerVehicle'},
                    exact: true
                }
            ],
            loading: false,
            snackbar: false,
            textError: '',
            fromDateMenu: false,
            toDateMenu: false,
            form: {
                customer_id: null,
                from_date: null,
                to_date: null,
                brand_id: null,
                positions: [],
            },
            user: null,
            isAdmin: false,
            customers: [],
            brands: [],
        }),
        computed: {
            from_date_value() {
                return this.formatDate(this.form.from_date);
            },
            to_date_value() {
                return this.formatDate(this.form.to_date);
            }
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.isAdmin) {
                // this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }

            tireBrandService.all().then(response => {
                this.brands = response.data.data;
                this.brands.unshift({
                    name: 'Todas',
                    id: null
                });
            }).catch(error => {
                console.error(error);
            });

            for (let i = 1; i <= 24; i++) {
                this.form.positions.push(i+'');
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            submit() {
                if (this.isAdmin && !this.form.customer_id) {
                    this.textError = 'Debe seleccionar un cliente';
                    this.snackbar = true;
                    return;
                }

                if (!this.form.from_date || !this.form.to_date) {
                    this.textError = 'Selecciona un rango de fecha';
                    this.snackbar = true;
                    return;
                }
                this.loading = true;
                vehicleFuelService.reportPerTires(this.form).then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Reporte de Combustible por Neumático.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
