<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/units/types" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Tipo Unidad</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitTypeForm :unittype="unittype"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitTypeForm from '@/components/units/type/UnitTypeForm';

    export default {
        name: 'UnitTypeAdd',
        components: {
            Breadcrumbs,
            UnitTypeForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Tipo de Unidades', disabled: false, to: {name: 'UnitType'}, exact: true},
                {text: 'Crear Tipo Unidad', disabled: true, to: {name: 'UnitTypeAdd'}, exact: true},
            ],
            unittype: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active',
                photo: '',
                photo_url: ''
            }
        })
    }
</script>

<style scoped>

</style>
