<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/tires/pressure" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Parte de Neumatico</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <PressureForm :pressure="pressure"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import PressureForm from '@/components/tires/pressures/PressureForm';

    export default {
        name: "PressureAdd",
        components: {
            Breadcrumbs,
            PressureForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Presión', disabled: false, to: {name: 'PressureAdd'}, exact: true},
                {text: 'Crear Presión', disabled: true, to: {name: 'PressureAdd'}, exact: true},
            ],
            pressure: {
                id: null,
                percentage: null,
                lost: null,
                status: 1,
                status_nice: 'Active'
            }
        })
    }
</script>

<style scoped>

</style>
