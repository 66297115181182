<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="administrators" :withExportParam="false" :hasDeleteItem="false"/>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import userService from "@/services/administrations/userService";

    export default {
        name: "Administrator",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Administradores', disabled: true, to: {name: 'Administrator'}, exact: true}
            ],
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            administrators: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 15,
                options: {
                    search: '',
                    roles: ['admin'],
                },
                loading: true,
                headers: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {text: 'Nombre', value: 'name'},
                    {text: 'Correo', value: 'email'},
                    {text: 'Estado', value: 'status'},
                    {text: 'Acciones', value: 'actions', sortable: false},
                ],
                title: 'Administradores',
                titleAddButton: 'Agregar Administrador',
                addlink: '/administration/administrators/create',
                editlink: '/administration/administrators/edit/',
            },
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.getDataFromApi()
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                userService.datatable(this.options.options).then(response => {
                    this.administrators = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>
