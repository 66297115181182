<template>
    <div class="pad">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1 class="style-title">Reporte de finales</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-form v-model="valid" @submit.prevent="submit">
                        <v-row>
                            <v-col v-if="isAdmin" cols="12" md="4">
                                <v-select
                                        v-model="form.customer_id"
                                        :items="customers"
                                        color="#009A3F"
                                        :rules="rules.customer_id"
                                        item-text="business_name"
                                        item-value="id"
                                        label="Cliente"
                                ></v-select>
                            </v-col>
                            <!--<v-col cols="12" md="4">
                                <v-menu v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Desde"
                                                prepend-icon="event"
                                                :rules="rules.from_date"
                                                readonly
                                                :value="from_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.from_date"
                                            no-title
                                            @input="fromDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu v-model="toDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Hasta"
                                                prepend-icon="event"
                                                :rules="rules.to_date"
                                                readonly
                                                :value="to_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.to_date"
                                            no-title
                                            @input="toDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>-->
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.brand_id_1"
                                        :items="brands"
                                        color="#009A3F"
                                        v-on:change="getByBrand('designs1')"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Marca 1"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.design_id_1"
                                        :items="designs1"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Diseño 1"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.measure_id_1"
                                        :items="measures"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Medida 1"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.status_1"
                                        :items="statuses"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Estado 1"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.brand_id_2"
                                        :items="brands"
                                        color="#009A3F"
                                        v-on:change="getByBrand('designs2')"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Marca 2"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.design_id_2"
                                        :items="designs2"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Diseño 2"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.measure_id_2"
                                        :items="measures"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Medida 2"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.status_2"
                                        :items="statuses"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Estado 2"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.brand_id_3"
                                        :items="brands"
                                        color="#009A3F"
                                        v-on:change="getByBrand('designs3')"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Marca 3"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.design_id_3"
                                        :items="designs3"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Diseño 3"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.measure_id_3"
                                        :items="measures"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Medida 3"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                        v-model="form.status_3"
                                        :items="statuses"
                                        color="#009A3F"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        label="Estado 3"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <div class="text-center">
                                    <v-btn class="button-export" type="submit" :disabled="!valid || loading" :loading="loading">
                                        Exportar
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import customerService from "@/services/administrations/customerService";
    import tireBrandService from "@/services/tires/tireBrandService";
    import tireDesignService from "@/services/tires/tireDesignService";
    import tireMeasureService from "@/services/tires/tireMeasureService";
    import tireService from "@/services/tires/tireService";

    export default {
        name: "FinalsReport",
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Reporte de Finales', disabled: true, to: {name: 'FinalsReport'}, exact: true}
            ],
            loading: false,
            snackbar: false,
            textError: '',
            fromDateMenu: false,
            toDateMenu: false,
            user: null,
            isAdmin: false,
            customers: [],
            form: {
                customer_id: null,
                from_date: null,
                to_date: null,
                brand_id_1: null,
                design_id_1: null,
                measure_id_1: null,
                status_1: null,
                brand_id_2: null,
                design_id_2: null,
                measure_id_2: null,
                status_2: null,
                brand_id_3: null,
                design_id_3: null,
                measure_id_3: null,
                status_3: null,
            },
            valid: false,
            rules: {
                customer_id: [
                    v => !!v || 'Cliente requerido',
                ],
                from_date: [
                    v => !!v || 'Fecha Desde requerida',
                ],
                to_date: [
                    v => !!v || 'Fecha Hasta requerida',
                ],
            },
            brands: [],
            designs1: [{
                id: null,
                name: 'Selecciona un diseño'
            }],
            designs2: [{
                id: null,
                name: 'Selecciona un diseño'
            }],
            designs3: [{
                id: null,
                name: 'Selecciona un diseño'
            }],
            measures: [{
                id: null,
                name: 'Todas'
            }],
            statuses: [
                {id: null, name: 'Todos'},
                {id: 2, name: 'Reencauche'},
                {id: 3, name: 'En Tránsito'},
                {id: 4, name: 'Desecho'},
                {id: 5, name: 'Reparación'},
            ]
        }),
        computed: {
            from_date_value() {
                return this.formatDate(this.form.from_date);
            },
            to_date_value() {
                return this.formatDate(this.form.to_date);
            }
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.isAdmin) {
                // this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }

            tireBrandService.all().then(response => {
                this.brands = response.data.data;
                this.brands.unshift({
                    name: 'Selecciona una marca',
                    id: null
                });
            }).catch(error => {
                console.error(error);
            });

            tireMeasureService.all().then(response => {
                this.measures = response.data.data;
                this.measures.unshift({
                    name: 'Selecciona una medida',
                    id: null
                });
            }).catch(error => {
                console.error(error);
            });
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            getByBrand(design) {
                if (design == 'designs1')  {
                    this.form.design_id_1 = null;
                    tireDesignService.allByBrand(this.form.brand_id_1).then(response => {
                        this.designs1 = response.data.data;
                        this.designs1.unshift({
                            name: 'Selecciona un diseño',
                            id: null
                        });
                    });
                } else if (design == 'designs2') {
                    this.form.design_id_2 = null;
                    tireDesignService.allByBrand(this.form.brand_id_2).then(response => {
                        this.designs2 = response.data.data;
                        this.designs2.unshift({
                            name: 'Selecciona un diseño',
                            id: null
                        });
                    });
                } else {
                    this.form.design_id_3 = null;
                    tireDesignService.allByBrand(this.form.brand_id_3).then(response => {
                        this.designs3 = response.data.data;
                        this.designs3.unshift({
                            name: 'Selecciona un diseño',
                            id: null
                        });
                    });
                }
            },
            submit() {
                let selected = true;
                const form = Object.assign({}, this.form);
                form.brand_id_1 = form.brand_id_1 ? form.brand_id_1 : '';
                form.design_id_1 = form.design_id_1 ? form.design_id_1 : '';
                form.measure_id_1 = form.measure_id_1 ? form.measure_id_1 : '';
                form.status_1 = form.status_1 ? form.status_1 : '';

                form.brand_id_2 = form.brand_id_2 ? form.brand_id_2 : '';
                form.design_id_2 = form.design_id_2 ? form.design_id_2 : '';
                form.measure_id_2 = form.measure_id_2 ? form.measure_id_2 : '';
                form.status_2 = form.status_2 ? form.status_2 : '';

                form.brand_id_3 = form.brand_id_3 ? form.brand_id_3 : '';
                form.design_id_3 = form.design_id_3 ? form.design_id_3 : '';
                form.measure_id_3 = form.measure_id_3 ? form.measure_id_3 : '';
                form.status_3 = form.status_3 ? form.status_3 : '';

                if (!form.brand_id_1 && !form.design_id_2 && !form.design_id_3) {
                    this.textError = 'Debe completar al menos una marca, diseño y medida';
                    this.snackbar = true;
                    return;
                }

                if((form.brand_id_1 && !form.design_id_1) || (form.brand_id_1 && !form.measure_id_1)) {
                    this.textError = 'Para el primer filtro de marca también debe seleccionar diseño y medida';
                    this.snackbar = true;
                    return;
                }

                if((form.brand_id_2 && !form.design_id_2) || (form.brand_id_2 && !form.measure_id_2)) {
                    this.textError = 'Para el segundo filtro de marca también debe seleccionar diseño y medida';
                    this.snackbar = true;
                    return;
                }

                if((form.brand_id_3 && !form.design_id_3) || (form.brand_id_3 && !form.measure_id_3)) {
                    this.textError = 'Para el tecer filtro de marca también debe seleccionar diseño y medida';
                    this.snackbar = true;
                    return;
                }

                this.loading = true;
                tireService.finalsExport(form).then(response => {
                     const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                     const link = document.createElement('a');
                     link.href = URL.createObjectURL(blob);
                     link.download = 'Reporte de Finales.xlsx';
                     link.click();
                     URL.revokeObjectURL(link.href);
                    console.log(response);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
