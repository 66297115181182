<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/administration/mobile-apps" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Versión de App</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <MobileAppForm :mobileApp="mobileApp"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import MobileAppForm from '@/components/administrations/mobile-app/MobileAppForm';
    import mobileAppService from "@/services/administrations/mobileAppService";

    export default {
        name: "MobileAppEdit",
        components: {
            Breadcrumbs,
            MobileAppForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Aplicación Móvil', disabled: false, to: {name: 'MobileApp'}, exact: true},
                {text: 'Editar Versión', disabled: true, to: {name: 'MobileAppEdit'}, exact: true},
            ],
            mobileApp: {
                name: null,
                version: null,
                file: null,
                file_url: null,
            }
        }),
        created() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                mobileAppService.show(id).then(response => {
                    this.mobileApp = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
