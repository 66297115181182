<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/others/documents" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Nuevo Documento</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <OtherDocumentForm :document="document"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import OtherDocumentForm from '@/components/others/document/OtherDocumentForm'

    export default {
        name: 'OtherDocumentAdd',
        components: {
            Breadcrumbs,
            OtherDocumentForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Documentos', disabled: false, to: {name: 'OtherDocument'}, exact: true},
                {text: 'Crear Documentos', disabled: true, to: {name: 'OtherDocumentAdd'}, exact: true},
            ],
            document: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active'
            }

        })
    }
</script>

<style scoped>

</style>
