<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/tires/wears" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Tipo de Desgaste</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <WearForm :wear="wear"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import WearForm from '@/components/tires/wear/WearForm'

    export default {
        name: 'WearAdd',
        components: {
            Breadcrumbs,
            WearForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Tipo de Desgastes', disabled: false, to: {name: 'TiresWear'}, exact: true},
                {text: 'Crear Tipo de Desgaste', disabled: true, to: {name: 'TiresWearAdd'}, exact: true},
            ],
            wear: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active',
                observation: '',
                photo: '',
                photo_url: ''
            }
        })
    }
</script>

<style scoped>

</style>
