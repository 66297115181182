<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/drivers" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Chofer</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <DriverForm :driver="driver"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import DriverForm from '@/components/drivers/DriverForm'
    import driverService from '@/services/driverService'

    export default {
        name: "DriverEdit",
        components: {
            Breadcrumbs,
            DriverForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Choferes', disabled: false, to: {name: 'Drivers'}, exact: true},
                {text: 'Editar Chofer', disabled: true, to: {name: 'DriverEdit'}, exact: true}
            ],
            driver: {
                id: null,
                customer_id: '',
                name: '',
                paternal_surname: '',
                maternal_surname: '',
                dni: '',
            }
        }),
        mounted() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                driverService.show(id).then(response => {
                    this.driver = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
