<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/fleets" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Operación</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <FleetForm :fleet="fleet"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import FleetForm from '@/components/fleets/FleetForm';

    export default {
        name: "FleetAdd",
        components: {
            Breadcrumbs,
            FleetForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Operación', disabled: false, to: {name: 'Fleets'}, exact: true},
                {text: 'Crear Operación', disabled: true, to: {name: 'FleetAdd'}, exact: true},
            ],
            fleet: {
                id: null,
                customer_id: '',
                name: '',
                status: 1,
            }
        })
    }
</script>

<style scoped>

</style>
