<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/units/routes" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Ruta</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitRouteForm :unitroute="unitroute"/>
                </v-card>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitRouteForm from '@/components/units/route/UnitRouteForm';
    import unitRouteService from '@/services/units/unitRouteService';

    export default {
        name: 'UnitRouteEdit',
        components: {
            Breadcrumbs,
            UnitRouteForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Rutas', disabled: false, to: {name: 'UnitRoute'}, exact: true},
                {text: 'Editar Ruta', disabled: true, to: {name: 'UnitRouteEdit'}, exact: true}
            ],
            unitroute: {
                id: null,
                name: '',
                status: null,
                status_nice: '',
            },
        }),
        created() {
            this.getUnitRouteById(this.$route.params.id);
        },
        methods: {
            getUnitRouteById(id) {
                unitRouteService.show(id).then(response => {
                    this.unitroute = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
