<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/process/fuel" class="icon-back"></router-link></div>
                    <h1 class="style-title">Insertar control de combustible</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <FuelForm :fuel="fuel"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import FuelForm from '@/components/process/fuel/FuelForm'

    export default {
        name: 'FuelAdd',
        components: {
            Breadcrumbs,
            FuelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Proceso', disabled: false, to: {name: 'Fuel'}, exact: true},
                {text: 'Crear Control', disabled: true, to: {name: 'FuelAdd'}, exact: true},
            ],
            fuel: {
                customer_id: null,
                id: null,
                vehicle_id: null,
                date: null,
                km_travel: null,
                time_travel: null,
                fuel_id: null,
                fuel_value: null,
                cost: null,
            }
        })
    }
</script>

<style scoped>

</style>
