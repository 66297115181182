<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/units/models" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Modelo</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitModelForm :unitmodel="unitmodel"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitModelForm from '@/components/units/model/UnitModelForm'

    export default {
        name: 'UnitModeloAdd',
        components: {
            Breadcrumbs,
            UnitModelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Modelos', disabled: false, to: {name: 'UnitModel'}, exact: true},
                {text: 'Crear Modelo', disabled: true, to: {name: 'UnitModelAdd'}, exact: true},
            ],
            unitmodel: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active',
                brand_of_unit_id: null
            }
        })
    }
</script>

<style scoped>

</style>
