<template>
    <div class="pad">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="drivers" @deleteItem="deleteItemConfirm"
                        :withCustomerSelect="isAdmin" @onSelectCustomerId="onSelectCustomerId"
                        @exportExcel="exportExcel"/>
        <v-dialog v-model="dialogDelete" max-width="400px" height="160px">
            <v-card>
                <v-card-title class="headline title-modal">¿Seguro de borrar este item?</v-card-title>
                
                    <div class="d-b pad-bottom">
                        <v-btn class="btn-cancel" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                        <v-btn color="button-export" :disabled="loading" :loading="loading" @click="deleteItemApi">
                            Confirmar
                        </v-btn>
                    </div>
                    
                
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import driverService from "@/services/driverService";

    export default {
        name: "Drivers",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Choferes', disabled: true, to: {name: 'Drivers'}, exact: true}
            ],
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            drivers: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 15,
                options: {
                    customer_id: null,
                    search: '',
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'customer.business_name'},
                    {text: 'Dni', value: 'dni'},
                    {text: 'Nombres', value: 'name'},
                    {text: 'Apellido Paterno', value: 'paternal_surname'},
                    {text: 'Apellido Materno', value: 'maternal_surname'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Choferes',
                titleAddButton: 'Agregar Chofer',
                addlink: '/drivers/create',
                editlink: '/drivers/edit/',
            },
            user: null,
            isAdmin: false,
            snackbar: false,
            textError: '',
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        created() {
            this.getDataFromApi();
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                driverService.datatable(this.options.options).then(response => {
                    this.drivers = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            onSelectCustomerId(e) {
                this.options.options.customer_id = e;
                this.getDataFromApi();
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                driverService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    console.error(error);
                });
            },
            exportExcel() {
                driverService.exportExcel(this.options.options).then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Choferes.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    };
</script>

<style scoped>

</style>
