<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/tires/pressure" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Presión</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <PressureForm :pressure="pressure"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import PressureForm from '@/components/tires/pressures/PressureForm';
    import tirePressureService from '@/services/tires/tirePressureService';

    export default {
        name: "PressureEdit",
        components: {
            Breadcrumbs,
            PressureForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Presión', disabled: false, to: {name: 'PressureAdd'}, exact: true},
                {text: 'Editar Presión', disabled: true, to: {name: 'PressureEdit'}, exact: true},
            ],
            pressure: {
                id: null,
                percentage: null,
                lost: null,
                status: 1,
                status_nice: 'Active'
            },
        }),
        created() {
            this.getTirePartById(this.$route.params.id);
        },
        methods: {
            getTirePartById(id) {
                tirePressureService.show(id).then(response => {
                    this.pressure = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
