<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                            color="#009A3F"
                            v-model="brand.name"
                            :rules="rules.name"
                            label="Marca"
                            required
                            outlined
                    ></v-text-field>

                    <div>
                        <v-checkbox
                                v-model="brand.retread"
                                label="Reencauche"
                                color="#009A3F"
                        ></v-checkbox>
                    </div>
                   <!-- <v-color-picker
                            v-model="brand.color"
                            dot-size="25"
                            swatches-max-height="200"
                    ></v-color-picker>-->
                    <div style="margin-bottom: 2rem">
                        <v-text-field v-model="brand.color"  hide-details class="ma-0 pa-0" solo>
                            <template v-slot:append>
                                <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">
                                        <div :style="swatchStyle" v-on="on" />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker v-model="brand.color" flat />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </div>

                    <v-select
                            color="#009A3F"
                            v-model="brand.status"
                            :items="allstatus"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Estado"
                    ></v-select>
                    <div class="centerdiv-container">
                        <v-img
                                max-height="150"
                                max-width="250"
                                src="~@/assets/images/llanta.png"
                        ></v-img>
                    </div>

                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="centerdiv-container button-form">
                        <v-btn :disabled="!valid || loading" type="submit" v-if="!brand.id" :loading="loading">
                            Agregar marca
                        </v-btn>
                    </div>
                    <div class="centerdiv-container button-form">
                        <v-btn :disabled="!valid || loading" type="submit" v-if="brand.id" :loading="loading">
                            Actualizar marca
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import tireBrandService from '@/services/tires/tireBrandService'

    export default {
        props: ["brand"],
        nama: 'BrandForm',
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ],

            // color: '#1976D2FF',
            mask: '!#XXXXXXXX',
            menu: false,
        }),
        mounted() {
        },
        computed: {
            swatchStyle() {
                // const { color, menu } = this;
                const menu = this.menu;
                const color = this.brand.color;
                return {
                    backgroundColor: color,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                }
            }
        },
        methods: {
            submit() {
                // console.log(this.brand); return;
                this.loading = true;
                // const brand = Object.assign({}, this.brand);
                // brand.color = brand.color.hex;
                if (this.brand.id) {
                    tireBrandService.update(this.brand).then(response => {
                        this.$router.push({path: '/tires/brands'})
                    }, error => {
                        console.log('error', error);
                    });
                } else {
                    tireBrandService.store(this.brand).then(response => {
                        this.$router.push({path: '/tires/brands'})
                    }, error => {
                        console.error('error', error);
                    });
                }
            },
            handleImage(e) {
                console.log(e);
            },
        }
    }
</script>

<style type="text/css">


</style>
