<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/tires/wears" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Tipo Desgaste</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <WearForm :wear="wear"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import WearForm from '@/components/tires/wear/WearForm';
    import tireWearService from '@/services/tires/tireWearService';

    export default {
        name: 'WearEdit',
        components: {
            Breadcrumbs,
            WearForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Tipo de Desgastes', disabled: false, to: {name: 'TiresWear'}, exact: true},
                {text: 'Editar Tipo de Desgaste', disabled: true, to: {name: 'TiresWearEdit'}, exact: true},
            ],
            wear: {
                id: null,
                name: '',
                status: null,
                status_nice: 'Active',
                observation: '',
                photo: '',
                photo_url: ''
            },
        }),
        created() {
            this.getTireWearById(this.$route.params.id);
        },
        methods: {
            getTireWearById(id) {
                tireWearService.show(id).then(response => {
                    this.wear = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
