<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <Datatableindex :options="options" :data="apps" @deleteItem="deleteItemConfirm" :withExport="false"/>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">¿Seguro de borrar este item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="error" :disabled="loading" :loading="loading" @click="deleteItemApi">
                        Sí, estoy seguro
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import mobileAppService from "@/services/administrations/mobileAppService";

    export default {
        name: "MobileApp",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Aplicación Móvil', disabled: true, to: {name: 'MobileApp'}, exact: true}
            ],
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            apps: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 5,
                options: {
                    search: '',
                },
                loading: true,
                headers: [
                    {text: 'Nombre', value: 'name'},
                    {text: 'Versión', value: 'version'},
                    {text: 'Archivo', value: 'file_url'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Aplicación Móvil',
                titleAddButton: 'Nueva Versión',
                addlink: '/administration/mobile-apps/create',
                editlink: '/administration/mobile-apps/edit/',
            },
            firstLoading: true,
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        created() {
            this.getDataFromApi()
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                mobileAppService.datatable(this.options.options).then(response => {
                    this.apps = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                mobileAppService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
