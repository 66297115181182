<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="unitwearlevel.name"
                            :rules="rules.name"
                            label="Tipo de Unidad"
                            color="#009A3F"
                            required
                            outlined
                    ></v-text-field>

                    <v-text-field
                            v-model="unitwearlevel.axis"
                            :rules="rules.axles"
                            label="Ejes"
                            type="number"
                            color="#009A3F"
                            required
                            outlined
                    ></v-text-field>

                    <v-text-field
                            v-model="unitwearlevel.position"
                            :rules="rules.positions"
                            label="Posiciones"
                            color="#009A3F"
                            type="number"
                            required
                            outlined
                    ></v-text-field>

                    <v-file-input
                            accept="image/*"
                            label="Logo"
                            color="#009A3F"
                            @change="handleImage($event)"
                            outlined
                            prepend-icon="mdi-camera"
                    ></v-file-input>

                    <div v-if="unitwearlevel.photo_url" class="centerdiv-container">
                        <v-img
                                max-width="22rem"
                                v-bind:src="unitwearlevel.photo_url"
                        ></v-img>
                    </div>

                    <v-select
                            v-model="unitwearlevel.status"
                            :items="allstatus"
                            outlined
                            item-text="name"
                            color="#009A3F"
                            item-value="id"
                            label="Estado"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                                :disabled="!valid || loading"
                                v-if="!unitwearlevel.id" :loading="loading"
                        >
                            Guardar
                        </v-btn>
                    </div>
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                                :disabled="!valid || loading"
                                v-if="unitwearlevel.id" :loading="loading"
                        >
                            Actualizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import unitWearLevelService from '@/services/units/unitWearLevelService'
    import resourceService from '@/services/resourceService'

    export default {
        props: ["unitwearlevel"],
        nama: 'UnitWearLevelForm',
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
                axles: [
                    v => !!v || 'Eje es requerido',
                ],
                positions: [
                    v => !!v || 'Posicion es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        methods: {
            submit() {
                if (this.unitwearlevel.id) {
                    unitWearLevelService.update(this.unitwearlevel).then(response => {
                        this.$router.push({path: '/units/wearlevels'})
                    }, error => {
                        console.log('error', error);
                    });
                } else {
                    unitWearLevelService.store(this.unitwearlevel).then(response => {
                        this.$router.push({path: '/units/wearlevels'})
                    }, error => {
                        console.error('error', error);
                    });
                }
            },
            handleImage(e) {
                const formData = new FormData();
                formData.append('file', e);
                formData.append('path', 'unitwearlevels');
                resourceService.uploadFile(formData).then(response => {
                    this.unitwearlevel.photo = response.data.data.name;
                    this.unitwearlevel.photo_url = response.data.data.url;
                }).catch(error => {
                    console.error('error', error);
                });
            },
        }
    }
</script>

<style type="text/css">


</style>
