<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/administration/customer/user" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Usuario</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <CustomerUserForm :user="user" v-if="user.id"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import CustomerUserForm from '@/components/administrations/customer-user/CustomerUserForm';
    import customerService from "@/services/administrations/customerService";

    export default {
        name: "CustomerUserEdit",
        components: {
            Breadcrumbs,
            CustomerUserForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Usuarios por Cliente', disabled: false, to: {name: 'UserCustomer'}, exact: true},
                {text: 'Editar Usuario por Cliente', disabled: true, to: {name: 'CustomerUserEdit'}, exact: true},
            ],
            user: {
                customer_id: '',
                name: '',
                dni: '',
                email: '',
                status: true,
            }
        }),
        created() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                customerService.showUser(id).then(response => {
                    this.user = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }
</script>

<style scoped>

</style>
