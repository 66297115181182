<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="pressure.percentage"
                            :rules="rules.percentage"
                            label="Porcentage"
                            color="#009A3F"
                            v-number
                            required
                            outlined
                    ></v-text-field>

                    <v-text-field
                            v-model="pressure.lost"
                            :rules="rules.lost"
                            label="Perdida"
                            color="#009A3F"
                            v-intNumber
                            required
                            outlined
                    ></v-text-field>

                    <v-select
                            v-model="pressure.status"
                            :items="allstatus"
                            outlined
                            color="#009A3F"
                            item-text="name"
                            item-value="id"
                            label="Estado"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                               :disabled="!valid || loading"
                               v-if="!pressure.id" :loading="loading"
                        >
                            Guardar
                        </v-btn>
                    </div>
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit"
                               :disabled="!valid || loading"
                               v-if="pressure.id"
                               :loading="loading"
                        >
                            Actualizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import tirePressureService from '@/services/tires/tirePressureService'

    export default {
        name: "PressureForm",
        props: ["pressure"],
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                percentage: [
                    v => !!v || 'Nombre es requerido',
                ],
                lost: [
                    v => v !== '' || 'Perdida es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        methods: {
            submit() {
                this.loading = true;
                if (this.pressure.id) {
                    tirePressureService.update(this.pressure).then(response => {
                        this.$router.push({path: '/tires/pressure'})
                    }, error => {
                        this.loading = false;
                        console.log('error', error);
                    });
                } else {
                    tirePressureService.store(this.pressure).then(response => {
                        this.$router.push({path: '/tires/pressure'})
                    }, error => {
                        this.loading = false;
                        console.error('error', error);
                    });
                }
            },
        }

    }
</script>

<style scoped>

</style>
