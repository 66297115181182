<template>
    <div class="pad">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1 class="style-title">Proyecciones</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-form v-model="valid" @submit.prevent="submit">
                        <v-row>
                            <v-col v-if="isAdmin" cols="12" md="4">
                                <v-select
                                        v-model="form.customer_id"
                                        :rules="rules.customer_id"
                                        :items="customers"
                                        v-on:change="getFleetsByCustomerId"
                                        item-text="business_name"
                                        item-value="id"
                                        label="Cliente"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Desde"
                                                :rules="rules.from_date"
                                                prepend-icon="event"
                                                readonly
                                                :value="from_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.from_date"
                                            no-title
                                            @input="fromDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu v-model="toDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                label="Hasta"
                                                :rules="rules.to_date"
                                                prepend-icon="event"
                                                readonly
                                                :value="to_date_value"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            locale="es-ES"
                                            v-model="form.to_date"
                                            no-title
                                            @input="toDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <fieldset class="py-2 px-2">
                                    <legend>Neumáticos</legend>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-select
                                                    v-model="form.brand_id"
                                                    :items="brands"
                                                    v-on:change="getByBrand"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Marcas"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select
                                                    v-model="form.design_id"
                                                    :items="designs"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Diseño"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select
                                                    v-model="form.measure_id"
                                                    :items="measures"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Medida"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <fieldset class="py-2 px-2">
                                    <legend>Unidad</legend>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-select
                                                    v-model="form.fleet_id"
                                                    :items="fleets"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Operación"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-autocomplete
                                                    v-model="form.vehicles"
                                                    :items="vehicles"
                                                    :loading="isLoadingVehicleSearch"
                                                    :search-input.sync="search"
                                                    hide-no-data
                                                    hide-selected
                                                    item-text="license_plate"
                                                    item-value="id"
                                                    label="Unidades"
                                                    placeholder="Busca una Unidad"
                                                    prepend-icon="mdi-database-search"
                                                    return-object
                                                    multiple
                                                    dense
                                                    chips
                                            ></v-autocomplete>
                                            <v-select
                                                    v-model="form.brand_of_unit_id"
                                                    :items="brandOfUnits"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Marca"
                                            ></v-select>
                                            <v-select
                                                    v-model="form.unit_model_id"
                                                    :items="unitModels"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Modelo"
                                            ></v-select>
                                            <v-select
                                                    v-model="form.unit_type_axis_id"
                                                    :items="unitTypeAxises"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Tipo de Eje"
                                            ></v-select>
                                            <v-select
                                                    v-model="form.route_id"
                                                    :items="routes"
                                                    outlined
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Ruta"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </v-col>
                            <v-col cols="12" md="6">
                                <fieldset class="py-2 px-2">
                                    <legend>% de Desgaste</legend>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-radio-group v-model="form.wear_percentage">
                                                <v-radio
                                                        color="#009A3F"
                                                        label="Todos"
                                                        :value="0"
                                                ></v-radio>
                                                <v-radio
                                                        color="#009A3F"
                                                        label="Mayor a %10"
                                                        :value="10"
                                                ></v-radio>
                                                <v-radio
                                                        color="#009A3F"
                                                        label="Mayor a %20"
                                                        :value="20"
                                                ></v-radio>
                                                <v-radio
                                                        color="#009A3F"
                                                        label="Mayor a %30"
                                                        :value="30"
                                                ></v-radio>
                                                <v-radio
                                                        color="#009A3F"
                                                        label="Mayor a %40"
                                                        :value="40"
                                                ></v-radio>
                                                <v-radio
                                                        color="#009A3F"
                                                        label="Mayor a %50"
                                                        :value="50"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                                <fieldset class="py-2 px-2">
                                    <legend>Reencauche</legend>
                                    <v-radio-group v-model="form.retread" row>
                                        <v-radio
                                                color="#009A3F"
                                                label="Todos"
                                                :value="0"
                                        ></v-radio>
                                        <v-radio
                                                color="#009A3F"
                                                label="Original"
                                                :value="1"
                                        ></v-radio>
                                        <v-radio
                                                color="#009A3F"
                                                label="Reencauche"
                                                :value="2"
                                        ></v-radio>
                                    </v-radio-group>
                                </fieldset>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <fieldset class="py-2 px-2">
                                    <legend>Ubicación del Neumático</legend>
                                    <v-row>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="1"
                                                    v-model="form.positions"
                                                    label="Posc. 1"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="2"
                                                    v-model="form.positions"
                                                    label="Posc. 2"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="3"
                                                    v-model="form.positions"
                                                    label="Posc. 3"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="4"
                                                    v-model="form.positions"
                                                    label="Posc. 4"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="5"
                                                    v-model="form.positions"
                                                    label="Posc. 5"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="6"
                                                    v-model="form.positions"
                                                    label="Posc. 6"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="7"
                                                    v-model="form.positions"
                                                    label="Posc. 7"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="8"
                                                    v-model="form.positions"
                                                    label="Posc. 8"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="9"
                                                    v-model="form.positions"
                                                    label="Posc. 9"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="10"
                                                    v-model="form.positions"
                                                    label="Posc. 10"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="11"
                                                    v-model="form.positions"
                                                    label="Posc. 11"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="12"
                                                    v-model="form.positions"
                                                    label="Posc. 12"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="13"
                                                    v-model="form.positions"
                                                    label="Posc. 13"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="14"
                                                    v-model="form.positions"
                                                    label="Posc. 14"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="15"
                                                    v-model="form.positions"
                                                    label="Posc. 15"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="16"
                                                    v-model="form.positions"
                                                    label="Posc. 16"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="17"
                                                    v-model="form.positions"
                                                    label="Posc. 17"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="18"
                                                    v-model="form.positions"
                                                    label="Posc. 18"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="19"
                                                    v-model="form.positions"
                                                    label="Posc. 19"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="20"
                                                    v-model="form.positions"
                                                    label="Posc. 20"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="21"
                                                    v-model="form.positions"
                                                    label="Posc. 21"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="22"
                                                    v-model="form.positions"
                                                    label="Posc. 22"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="23"
                                                    v-model="form.positions"
                                                    label="Posc. 23"
                                            ></v-checkbox>
                                            <v-checkbox
                                                    color="#009A3F"
                                                    value="24"
                                                    v-model="form.positions"
                                                    label="Posc. 24"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" class="text-center">
                                <v-btn depressed class="button-export" type="submit" :disabled="!valid || loading" :loading="loading">
                                    Exportar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import customerService from "@/services/administrations/customerService";
    import tireBrandService from "@/services/tires/tireBrandService";
    import tireDesignService from "@/services/tires/tireDesignService";
    import tireMeasureService from "@/services/tires/tireMeasureService";
    import unitService from "@/services/units/unitService";
    import unitBrandService from "@/services/units/unitBrandService";
    import unitModelService from "@/services/units/unitModelService";
    import unitWearLevelService from "@/services/units/unitWearLevelService";
    import unitRouteService from "@/services/units/unitRouteService";
    import inspectionService from "@/services/inspectionService";
    import fleetService from '@/services/fleetService';

    export default {
        name: "Projection",
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {
                    text: 'Reporte de Proyección',
                    disabled: true,
                    to: {name: 'Projection'},
                    exact: true
                }
            ],
            rules: {
                customer_id: [
                    v => !!v || 'Cliente es requerido',
                ],
                from_date: [
                    v => !!v || 'Rango de fecha requerido',
                ],
                to_date: [
                    v => !!v || 'Rango de fecha requerido',
                ],
            },
            valid: false,
            loading: false,
            snackbar: false,
            textError: '',
            fromDateMenu: false,
            toDateMenu: false,
            form: {
                customer_id: null,
                fleet_id: null,
                from_date: null,
                to_date: null,
                brand_id: null,
                design_id: null,
                measure_id: null,
                positions: [],
                vehicles: [],
                brand_of_unit_id: null,
                unit_model_id: null,
                unit_type_axis_id: null,
                route_id: null,
                wear_percentage: 0,
                retread: 0,
            },
            customers: [],
            brands: [],
            designs: [{
                id: null,
                name: 'Todos'
            }],
            measures: [{
                id: null,
                name: 'Todas'
            }],
            vehicles: [],
            search: null,
            isLoadingVehicleSearch: false,
            brandOfUnits: [{
                id: null,
                name: 'Todas'
            }],
            unitModels: [{
                id: null,
                name: 'Todos'
            }],
            unitTypeAxises: [{
                id: null,
                name: 'Todos'
            }],
            routes: [{
                id: null,
                name: 'Todas'
            }],
            user: null,
            isAdmin: false,
            fleets: []
        }),
        watch: {
            search(val) {
                // Items have already been loaded
                // if (this.tireTemplates.length > 0) return;

                // Items have already been requested
                if (this.isLoadingVehicleSearch) return;

                this.isLoadingVehicleSearch = true;
                // Lazily load input items
                unitService.search(val, 1).then(response => {
                    this.vehicles = response.data.data;
                    this.isLoadingVehicleSearch = false;
                }).catch(error => {
                    this.isLoadingVehicleSearch = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
        },
        computed: {
            from_date_value() {
                return this.formatDate(this.form.from_date);
            },
            to_date_value() {
                return this.formatDate(this.form.to_date);
            }
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if(role.name === 'customer') {
                    this.isAdmin = false;
                }
            });
            if (this.isAdmin) {
                // this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            } else {
                fleetService.all().then(response => {
                    this.fleets = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            }

            tireBrandService.all().then(response => {
                this.brands = response.data.data;
                this.brands.unshift({
                    name: 'Todas',
                    id: null
                });
            });

            tireMeasureService.all().then(response => {
                this.measures = response.data.data;
                this.measures.unshift({
                    name: 'Todas',
                    id: null
                });
            });

            unitBrandService.all().then(response => {
                this.brandOfUnits = response.data.data;
                this.brandOfUnits.unshift({
                    name: 'Todas',
                    id: null
                });
            });

            unitModelService.all().then(response => {
                this.unitModels = response.data.data;
                this.unitModels.unshift({
                    name: 'Todos',
                    id: null
                });
            });

            unitWearLevelService.all().then(response => {
                this.unitTypeAxises = response.data.data;
                this.unitTypeAxises.unshift({
                    name: 'Todos',
                    id: null
                });
            });

            unitRouteService.all().then(response => {
                this.routes = response.data.data;
                this.routes.unshift({
                    name: 'Todas',
                    id: null
                });
            });

            for (let i = 1; i <= 24; i++) {
                this.form.positions.push(i+'');
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            getByBrand() {
                this.form.design_id = null;
                tireDesignService.allByBrand(this.form.brand_id).then(response => {
                    this.designs = response.data.data;
                    this.designs.unshift({
                        name: 'Todos',
                        id: null
                    });
                });
            },
            onSelectVehicle(e) {
                this.form.vehicle_id = e.id;
            },
            getFleetsByCustomerId() {
                fleetService.all(this.form.customer_id).then(response => {
                    this.fleets = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
            },
            submit() {
                const form = Object.assign({}, this.form);
                let vehicles = [];
                form.vehicles.forEach(item => {
                    vehicles.push(item.id);
                });
                form.vehicles = vehicles;
                this.loading = true;

                inspectionService.downloadProjectionReport(form).then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Reporte de Proyección.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            }
        }
    }
</script>

<style scoped>

</style>
