<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/tires/designs" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Diseño</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <DesignForm :design="design"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import DesignForm from '@/components/tires/design/DesignForm'
    import tireDesignService from '@/services/tires/tireDesignService';

    export default {
        name: 'DesignEdit',
        components: {
            Breadcrumbs,
            DesignForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Diseños', disabled: false, to: {name: 'TiresDesign'}, exact: true},
                {text: 'Editar Diseño', disabled: true, to: {name: 'TiresDesignEdit'}, exact: true}
            ],
            design: {
                id: null,
                name: '',
                status: null,
                retread: null,
                brand_id: null
            }
        }),
        mounted() {
            this.getTireDesignById(this.$route.params.id);
        },
        methods: {
            getTireDesignById(id) {
                tireDesignService.show(id).then(response => {
                    this.design = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
