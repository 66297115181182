<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" d="8">
                <h1 class="style-title">{{options.title}}</h1>
            </v-col>
        </v-row>
        <v-row v-if="withCustomerSelect">
            <v-col cols="12" sm="6" md="6">
                <v-select
                        v-model="customerId"
                        :items="customers"
                        @change="selectCustomer"
                        item-text="business_name"
                        item-value="id"
                        label="Seleccione el Cliente"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="6">
                <v-text-field label="Buscar..." v-model="options.options.search" solo class="border-input"><v-icon slot="append" color="#000">mdi-magnify</v-icon></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" v-if="withExportParam">
                <v-btn class="button-export" @click="exportExcel">Exportar</v-btn>
            </v-col>

            <v-col v-if="options.addlink" cols="12" sm="3" md="3" class="button-submit d-flex">
                <v-btn :to="options.addlink" class="button-add ml-auto">+ {{options.titleAddButton}}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-container>
                <v-data-table
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15],
                            'items-per-page-text': 'Filas por página',
                        }"
                        :page="options.page"
                        :items-per-page="options.itemsPerPage"
                        :headers="options.headers"
                        :items="data"
                        :options.sync="options.options"
                        :loading="options.loading"
                        :server-items-length="options.itemsLength"
                        class="elevation-1">
                    <template v-slot:item.retreading="{ item }">
                        <v-simple-checkbox
                                v-model="item.retreading"
                                disabled
                        ></v-simple-checkbox>
                    </template>
                    <template v-slot:item.customer_user="{ item }">
                        {{item.customer_user[0].customer.business_name}}
                    </template>
                    <template v-slot:item.otd="{ item }">
                        {{item.otd != null ? item.otd.toFixed(1) : item.otd}}
                    </template>
                    <template v-slot:item.status="{ item }">
                        {{item.status_nice}}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn icon :to="`${options.editlink}` + item.id">
                            <v-icon
                                    small
                                    class="mr-2"
                                    color="#009A3F"
                            >
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn icon v-if="hasDeleteItemCom">
                            <v-icon
                                    small
                                    @click="deleteItem(item)"
                                    color="#EB5757"
                            >
                                mdi-delete
                            </v-icon>
                        </v-btn>
                        <v-btn icon v-if="options.extraLink" :to="`${options.extraLink}` + item.id">
                            <v-icon small
                                    class="mr-2"
                            >
                                mdi-wrench
                            </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.date="{ item }">
                        {{item.date | dateFilter('DD/MM/YYYY')}}
                    </template>
                    <template v-slot:item.photo_url="{ item }">
                        <img v-bind:src="item.photo_url" alt="" class="img">
                    </template>
                    <template v-slot:item.file_url="{ item }">
                        <v-btn :href="item.file_url" elevation="2">Descargar</v-btn>
                    </template>
                </v-data-table>

            </v-container>
        </v-row>
    </v-container>
</template>

<script>
    import customerService from "@/services/administrations/customerService";

    export default {
        props: ['options', 'data', 'withCustomerSelect', 'withExport', 'hasDeleteItem'],
        name: 'Datatableindex',
        components: {},
        data: () => ({
            customerId: null,
            customers: [{
                business_name: 'Todos',
                id: null
            }],
            hasDeleteItemCom: true,
            withExportParam: true,
        }),
        watch: {
            withCustomerSelect: function (newVal, oldVal) {
                if (newVal) {
                    customerService.all().then(response => {
                        this.customers = response.data.data;
                        this.customers.unshift({
                            business_name: 'Todos',
                            id: null
                        });
                    });
                }
            },
        },
        mounted() {
            if (this.hasDeleteItem !== undefined) {
                this.hasDeleteItemCom = this.hasDeleteItem;
            }
            if (this.withExport === undefined) {
                this.withExportParam = true;
            } else {
                this.withExportParam = this.withExport;
            }
            if (this.withCustomerSelect) {
                customerService.all().then(response => {
                    this.customers = response.data.data;
                    this.customers.unshift({
                        business_name: 'Todos',
                        id: null
                    });
                });
            }
        },
        methods: {
            deleteItem(item) {
                this.$emit('deleteItem', item);
            },
            selectCustomer() {
                this.$emit('onSelectCustomerId', this.customerId);
            },
            exportExcel() {
                this.$emit('exportExcel', null);
            }
        }
    }
</script>

<style scoped type="text/css">


    .style-title{
        font-weight: 700 !important;
        font-size: 36px !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        color: #009A3F !important;
    }
    .button-add {
        /*width: 238px !important;*/
        background-color: #FF7302 !important;
        height: 50px !important;
    }

    .img {
        max-width: 15rem;
        max-height: 6rem;
    }
</style>
