<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="measure.name"
                            :rules="rules.name"
                            color="#009A3F"
                            label="Medida"
                            required
                            outlined
                    ></v-text-field>

                    <v-select
                            v-model="measure.status"
                            :items="allstatus"
                            color="#009A3F"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Estado"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit" :disabled="!valid || loading" v-if="!measure.id" :loading="loading">
                            Guardar
                        </v-btn>
                    </div>
                    <div class="button-submit centerdiv-container button-form">
                        <v-btn type="submit" :disabled="!valid || loading" v-if="measure.id" :loading="loading">
                            Actualizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import tireMeasureService from '@/services/tires/tireMeasureService'

    export default {
        props: ["measure"],
        name: 'MeasureForm',
        components: {},
        data: () => ({
            loading: false,
            valid: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
            status: {id: 1, name: 'Activo'},
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        methods: {
            submit() {
                this.loading = true;
                if (this.measure.id) {
                    tireMeasureService.update(this.measure).then(response => {
                        this.$router.push({path: '/tires/measures'})
                    }, error => {
                        console.log('error', error);
                    });
                } else {
                    tireMeasureService.store(this.measure).then(response => {
                        this.$router.push({path: '/tires/measures'})
                    }, error => {
                        console.error('error', error);
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
