<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/units" class="icon-back"></router-link></div>
                    <h1 class="style-title">Editar Vehículo</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <UnitForm :unit="unit" v-if="unit.id"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import UnitForm from '@/components/units/unit/UnitForm';
    import unitService from '@/services/units/unitService';

    export default {
        name: "UnitEdit",
        components: {
            Breadcrumbs,
            UnitForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Unidades', disabled: false, to: {name: 'Units'}, exact: true},
                {text: 'Editar Unidad', disabled: true, to: {name: 'UnitEdit'}, exact: true},
            ],
            unit: {
                id: 0,
                customer_id: null,
                license_plate: null,
                vehicle_number: null,
                brand_of_unit_id: null,
                unit_model_id: null,
                unit_type_id: null,
                unit_type_axis_id: null,
                fleet_id: null,
                flats: null,
                trip_counter: null,
            }
        }),
        created() {
            this.getById(this.$route.params.id);
        },
        methods: {
            getById(id) {
                unitService.show(id).then(response => {
                    this.unit = response.data.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
