<template>
    <div class="pad">
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="snackbarColorBtn"
                       text
                       v-bind="attrs"
                       @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <Breadcrumbs :items="breadcrumbs"/>

        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8">
                    <h1 class="style-title">{{options.title}}</h1>
                </v-col>
            </v-row>
            <v-row v-if="isAdmin">
                <v-col cols="12" sm="12" md="6">
                    <v-select
                            v-model="options.options.customer_id"
                            :items="customers"
                            @change="onSelectCustomerId"
                            item-text="business_name"
                            item-value="id"
                            label="Seleccione el Cliente"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Buscar..." v-model="options.options.search" solo append-icon="search"></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                    <v-btn class="button-export" @click="exportExcel">Exportar</v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="3" class="button-submit d-flex">
                    <v-btn :to="options.addlink" class="button-add ml-auto">+{{options.titleAddButton}}</v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="showBulkLoad">
                    <label>Plantilla para Cargar Vehículos</label> <br>
                    <v-btn class="button-export" elevation="2" :href="urlDownloadTemplate">Descargar</v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="showBulkLoad">
                    <label>Carga Masiva</label>
                    <v-file-input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            label="Seleccione un archivo"
                            @change="handleFile($event)"
                    ></v-file-input>
                </v-col>


               <!-- <v-col v-if="options.addlink" cols="12" sm="3" md="3" class="button-submit d-flex">
                    <v-btn :to="options.addlink" class="button-add ml-auto">+{{options.titleAddButton}}</v-btn>
                </v-col>-->
            </v-row>
            <v-row>
                <v-container>
                    <v-data-table
                    :footer-props="{
                            'items-per-page-options': [5, 10, 15],
                            'items-per-page-text': 'Filas por página',
                        }"
                            :page="options.page"
                            :items-per-page="options.itemsPerPage"
                            :headers="options.headers"
                            :items="units"
                            :options.sync="options.options"
                            :loading="options.loading"
                            :server-items-length="options.itemsLength"
                            class="elevation-1">
                        <template v-slot:item.retreading="{ item }">
                            <v-simple-checkbox
                                    v-model="item.retreading"
                                    disabled
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.customer_user="{ item }">
                            {{item.customer_user[0].customer.business_name}}
                        </template>
                        <template v-slot:item.otd="{ item }">
                            {{item.otd != null ? item.otd.toFixed(1) : item.otd}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon :to="`${options.editlink}` + item.id">
                                <v-icon
                                        small
                                        class="mr-2"
                                >
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon
                                        small
                                        @click="deleteItemConfirm(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                            <v-btn icon v-if="options.extraLink" :to="`${options.extraLink}` + item.id">
                                <v-icon small
                                        class="mr-2"
                                >
                                    mdi-wrench
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.photo_url="{ item }">
                            <img v-bind:src="item.photo_url" alt="" class="img">
                        </template>
                        <template v-slot:item.file_url="{ item }">
                            <v-btn :href="item.file_url" elevation="2">Descargar</v-btn>
                        </template>
                    </v-data-table>

                </v-container>
            </v-row>
        </v-container>



        <!--<v-container>
            <v-row>
                <v-col cols="12" sm="12" md="12" v-if="showBulkLoad">
                    <label>Plantilla para Cargar Vehículos</label> <br>
                    <v-btn elevation="2" :href="urlDownloadTemplate">Descargar</v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="showBulkLoad">
                    <label>Carga Masiva</label>
                    <v-file-input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            label="Seleccione un archivo"
                            @change="handleFile($event)"
                    ></v-file-input>
                </v-col>
            </v-row>
        </v-container>-->

        <!--<Datatableindex :options="options" :data="units" @deleteItem="deleteItemConfirm" :withCustomerSelect="isAdmin"
                        @onSelectCustomerId="onSelectCustomerId" @exportExcel="exportExcel"/>-->


        <v-dialog v-model="dialogDelete" max-width="400px" height="160px">
            <v-card>
                <v-card-title class="headline title-modal">¿Seguro de borrar este item?</v-card-title>
                
                    <div class="d-b pad-bottom">
                        <v-btn class="btn-cancel" :disabled="loading" @click="dialogDelete = false">Cancelar</v-btn>
                        <v-btn color="button-export" :disabled="loading" :loading="loading" @click="deleteItemApi">
                            Confirmar
                        </v-btn>
                    </div>
                    
                
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import Datatableindex from '@/components/shared/Datatableindex'
    import unitService from "@/services/units/unitService";
    import customerService from "@/services/administrations/customerService";
    import * as XLSX from 'xlsx';

    const ENDPOINT_PATH_PUBLIC = process.env.VUE_APP_BASE_URL_PUBLIC;

    export default {
        name: "Units",
        components: {
            Breadcrumbs,
            Datatableindex,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Vehículos', disabled: true, to: {name: 'Units'}, exact: true}
            ],
            snackbarColorBtn: 'red',
            snackbar: false,
            textError: '',
            loading: false,
            dialogDelete: false,
            itemDelete: null,
            units: [],
            options: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                options: {
                    search: '',
                    customer_id: null
                },
                loading: true,
                headers: [
                    {text: 'Empresa', value: 'customer.business_name'},
                    {text: 'Placa', value: 'license_plate'},
                    {text: 'Número', value: 'vehicle_number'},
                    {text: 'Marca', value: 'brand_of_unit.name'},
                    {text: 'Modelo', value: 'unit_model.name'},
                    {text: 'Tipo de Unidad', value: 'unit_type.name'},
                    {text: 'Tipo de eje', value: 'unit_type_axis.name'},
                    {text: 'Operación', value: 'fleet.name'},
                    {text: 'Pisos', value: 'flats'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                title: 'Unidades',
                titleAddButton: 'Agregar Unidad',
                addlink: '/units/create',
                editlink: '/units/edit/',
                extraLink: '/units/tires/',
                // editlink: '/units/edit/',
            },
            customers: [{
                business_name: 'Todos',
                id: null
            }],
            user: null,
            isAdmin: false,
            firstLoading: true,
            showBulkLoad: true,
            urlDownloadTemplate: ENDPOINT_PATH_PUBLIC + 'templates/plantilla_carga_masiva_vehículos.xlsx',
        }),
        computed: {
            optionsServerSide() {
                return this.options.options;
            }
        },
        watch: {
            optionsServerSide: {
                handler() {
                    if (!this.firstLoading) {
                        this.getDataFromApi();
                    } else {
                        this.firstLoading = false;
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.user = this.$store.getters.userSession;
            if (this.user.roles.find(role => role.name === 'customer')) {
                this.urlDownloadTemplate = ENDPOINT_PATH_PUBLIC + 'templates/plantilla_carga_masiva_vehículos.xlsx';
            } else {
                this.urlDownloadTemplate = ENDPOINT_PATH_PUBLIC + 'templates/plantilla_carga_masiva_vehículos_pts.xlsx';
            }
            this.getDataFromApi();

            this.isAdmin = true;
            this.user.roles.forEach(role => {
                if (role.name === 'customer') {
                    this.isAdmin = false;
                }
            });

            if (this.isAdmin) {
                // this.isAdmin = true;
                customerService.all().then(response => {
                    this.customers = response.data.data;
                    this.customers.unshift({
                        business_name: 'Todos',
                        id: null
                    });
                });
            }
        },
        methods: {
            getDataFromApi() {
                this.options.loading = true;
                unitService.datatable(this.options.options).then(response => {
                    this.units = response.data.items;
                    this.options.itemsLength = response.data.total;
                    this.options.loading = false;
                });
            },
            onSelectCustomerId(e) {
                this.options.options.customer_id = e;
                this.options.options.page = 1;
                this.getDataFromApi();
            },
            deleteItemConfirm(item) {
                this.dialogDelete = true;
                this.itemDelete = item;
            },
            deleteItemApi() {
                this.loading = true;
                unitService.delete(this.itemDelete.id).then(response => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.getDataFromApi();
                }).catch(error => {
                    this.loading = false;
                    this.dialogDelete = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.error(error);
                });
            },
            handleFile(e) {
                if (!e) {
                    return;
                }
                const formData = new FormData();
                formData.append('file', e);
                this.overlay = true;
                unitService.bulkLoad(formData).then(response => {
                    this.overlay = false;
                    if (response.data.data.length > 0) {
                        const dataExport = [];
                        if (this.user.roles.find(role => role.name === 'customer')) {
                            dataExport.push([
                                "Placa",
                                "Número",
                                "Marca",
                                "Modelo",
                                "Tipo",
                                "Tipo de Eje",
                                "Operación",
                                "Piso",
                                "Error",
                            ]);

                            response.data.data.forEach(item => {
                                dataExport.push([
                                    item[0],
                                    item[1],
                                    item[2],
                                    item[3],
                                    item[4],
                                    item[5],
                                    item[6],
                                    item[7],
                                    item[8],
                                ]);
                            });
                        } else {
                            dataExport.push([
                                "RUC",
                                "Placa",
                                "Número",
                                "Marca",
                                "Modelo",
                                "Tipo",
                                "Tipo de Eje",
                                "Operación",
                                "Piso",
                                "Error",
                            ]);
                            response.data.data.forEach(item => {
                                dataExport.push([
                                    item[0],
                                    item[1],
                                    item[2],
                                    item[3],
                                    item[4],
                                    item[5],
                                    item[6],
                                    item[7],
                                    item[8],
                                    item[9],
                                ]);
                            });
                        }
                        const ws = XLSX.utils.aoa_to_sheet(dataExport);
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                        XLSX.writeFile(wb, 'vehículos_no_cargados.xlsx');
                        this.textError = 'Algunos vehículos no puedieron cargarse, se descargará un excel indicando cual fue el problema';
                    } else {
                        this.textError = 'Los vehículos se han cargado exitosamente';
                    }
                    this.snackbarColorBtn = 'blue';
                    this.snackbar = true;
                    this.getDataFromApi()

                }).catch(error => {
                    console.error('error', error);
                    this.overlay = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },

            exportExcel() {
                unitService.exportExcel(this.options.options).then(response => {
                    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'Unidades.xlsx';
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.loading = false;
                }).catch(error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    this.loading = false;
                });
            }
        }
    };
</script>

<style scoped>
    .button-add {
        width: 238px !important;
        height: 50px !important;
    }
</style>
