<template>
    <div class="pad">
        <Breadcrumbs :items="breadcrumbs"/>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" d="8" class="d-flex justify-left">
                    <div><router-link to="/tires/fails" class="icon-back"></router-link></div>
                    <h1 class="style-title">Agregar Falla de Neumatico</h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-card width='90%'>
                    <FailForm :fail="fail"/>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumbs from '@/components/layout/Breadcrumbs';
    import FailForm from '@/components/tires/fail/FailForm'

    export default {
        name: 'FailAdd',
        components: {
            Breadcrumbs,
            FailForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Fallas del Neumático', disabled: false, to: {name: 'TiresFail'}, exact: true},
                {text: 'Crear Falla de Neumatico', disabled: true, to: {name: 'TiresFailAdd'}, exact: true},
            ],
            fail: {
                id: null,
                name: '',
                status: 1,
                status_nice: 'Active',
                part_id: null,
                comment: '',
                photo: '',
                photo_url: '',
            }
        })
    }
</script>

<style scoped>

</style>
